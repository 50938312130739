import { Request } from "../model";
import { doGet, doPost } from "./http.service";
import { MemberService } from "./new-member.service";

export class RestApiMemberService implements MemberService {
    private readonly apiUrl: string;

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    private absolute(url: string): string {
        return `${this.apiUrl}${url}`;
    }

    getRequestById(id: string): Promise<Request> {
        const url = this.absolute(`/v1/requests/${id}`);
        return doGet<Request>(url);
    }

    acceptRequest(request: Request): (() => Promise<void>) | undefined {
        const link = request._links.accept;

        if (!link.available) {
            return undefined;
        }

        return () => doPost(link.href);
    }

    rejectRequest(request: Request): (() => Promise<void>) | undefined {
        const link = request._links.reject;

        if (!link.available) {
            return undefined;
        }

        return () => doPost(link.href);
    }
}
