import { useOutsideClick } from "@vaultinum/shared-web-app-sdk";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Size, SIZE_MAP } from "../../referentials";
import { useHover } from "../../utils/hooks";
import { BubbleDotsIcon, ExternalLinkIcon, Loader } from "../index";

export type DropDownElements = {
    label: string;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

type ButtonProps = {
    onClick?: (e: React.MouseEvent<HTMLElement>) => Promise<void> | void;
    disabled?: boolean;
    label?: string;
    loading?: boolean;
    withLoader?: boolean;
    icon?: React.ReactNode;
    variant?: "default" | "danger" | "link" | "externalLink" | "iconButton" | "dropdown";
    type?: "reset" | "submit" | "button";
    size?: Size;
    underline?: boolean;
    color?: "primary" | "danger";
    outlined?: boolean;
    fontBold?: boolean;
    options?: DropDownElements[];
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type" | "disabled">;

const LinkButton = ({
    disabled,
    label,
    loading,
    variant = "default",
    size = "md",
    type = "button",
    underline,
    color = "primary",
    fontBold,
    onClick,
    ...props
}: ButtonProps): JSX.Element => {
    return (
        <button
            disabled={disabled || loading}
            className={classNames("underline flex items-center", {
                "text-color-disabled cursor-not-allowed": disabled,
                "text-primary hover:font-bold cursor-pointer": !disabled,
                "text-primary-red hover:font-bold cursor-pointer": color === "danger",
                "text-sm": size === "sm",
                "text-base": size === "md",
                "text-xs": size === "xs",
                "font-bold": fontBold,
                underline: underline,
                "no-underline": !underline
            })}
            type={type}
            {...props}
            onClick={e => onClick?.(e)}
        >
            {label}
            {variant === "externalLink" && (
                <ExternalLinkIcon
                    className={classNames("ml-1.5 mt-1", {
                        "fill-color-disabled cursor-not-allowed": disabled,
                        "fill-primary hover:font-bold": !disabled
                    })}
                />
            )}
        </button>
    );
};

const SimpleButton = ({ disabled, label, loading, icon, variant = "default", type = "button", className, ...props }: ButtonProps): JSX.Element => {
    return (
        <button
            disabled={disabled || loading}
            className={classNames("border flex items-center justify-center rounded-none font-normal gap-2 px-6 py-2", className, {
                "bg-disabled text-color-disabled border-disabled cursor-not-allowed": loading || (variant === "default" && disabled),
                "bg-danger-disabled text-white border-danger-disabled cursor-not-allowed": variant === "danger" && disabled && !loading,
                "bg-white text-primary border-primary hover:bg-primary group-hover:bg-primary hover:text-white group-hover:text-white":
                    variant === "default" && !disabled && !loading,
                "text-danger-high bg-white border-danger-high hover:bg-danger-high hover:text-white group-hover:bg-danger-high group-hover:text-white":
                    variant === "danger" && !disabled && !loading
            })}
            type={type}
            {...props}
        >
            {loading ? <Loader position="relative" /> : icon && <span className="w-5 h-5">{icon}</span>}
            {label}
        </button>
    );
};

const IconButton = ({ disabled, loading, icon, outlined, className, label, ...props }: ButtonProps): JSX.Element => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const isHover = useHover(buttonRef);
    return (
        <button
            ref={buttonRef}
            disabled={disabled || loading}
            type="button"
            className={classNames(className, "p-0", {
                "border rounded border-primary bg-white hover:bg-primary": outlined
            })}
            {...props}
        >
            <div
                className={classNames({
                    "fill-primary": !isHover,
                    "fill-white": isHover,
                    "flex items-center gap-1 px-2": label,
                    "text-white": label && isHover,
                    "text-primary": label && !isHover
                })}
            >
                {icon}
                {label && <label className="cursor-pointer">{label}</label>}
            </div>
        </button>
    );
};

const DropDownButton = ({ disabled, loading, options, ...props }: ButtonProps): JSX.Element => {
    const [toggle, setToggle] = useState<boolean>(false);
    const ref = useOutsideClick<HTMLButtonElement>(() => setToggle(false));

    return (
        <div className="relative">
            <button
                {...props}
                className="inline-flex self-center items-center p-2 text-sm font-medium text-center rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-50"
                type="button"
                onClick={() => setToggle(prev => !prev)}
                ref={ref}
                disabled={disabled || loading}
            >
                <BubbleDotsIcon className={classNames("fill-color-disabled", SIZE_MAP.md)} />
            </button>
            <div
                className={classNames("absolute z-10 p-2 bg-white divide-gray-100 rounded-lg shadow border border-super-light-grey min-w-48 max-w-72", {
                    block: toggle,
                    hidden: !toggle
                })}
            >
                <ul className="flex flex-col gap-y-2 divide-y text-sm text-gray-700">
                    {options?.map((element, index) => (
                        <li key={index} className="flex items-center rounded-lg min-h-12 px-4 hover:bg-primary-xlight cursor-pointer w-full">
                            <button onClick={element.onClick}>{element.label}</button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export function Button({ loading, withLoader = false, onClick, ...props }: ButtonProps): JSX.Element {
    const [isLoading, setIsLoading] = useState(!!loading);

    useEffect(() => {
        setIsLoading(!!loading);
    }, [loading]);

    const wrapOnClickWithLoader =
        onClick &&
        (async (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            try {
                setIsLoading(true);

                await onClick(e);
            } finally {
                setIsLoading(false);
            }
        });

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        void onClick?.(e);
    };

    const onClickCallback = withLoader ? wrapOnClickWithLoader : handleClick;

    switch (props.variant) {
        case "link":
        case "externalLink":
            return <LinkButton loading={isLoading} onClick={onClickCallback} {...props} />;
        case "iconButton":
            return <IconButton loading={isLoading} onClick={onClickCallback} {...props} />;
        case "dropdown":
            return <DropDownButton loading={isLoading} onClick={onClickCallback} {...props} />;
        default:
            return <SimpleButton loading={isLoading} onClick={onClickCallback} {...props} />;
    }
}
