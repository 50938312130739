import { Deposit, DepositMember, DepositStore, Holder, ManagedBy, Member, UserInfo } from "../model";
import { doDelete, doGet, doPost } from "./api.service";

const absolute = (url: string): string => {
    return `${process.env.REACT_APP_DEPOSIT_API_URL}${url}`;
};

export function getDepositStores(member: Member): Promise<DepositStore[]> {
    return doGet(member._links.depositStores.href);
}

export function searchInDepositStores(member: Member, queryParam: string): Promise<DepositStore[]> {
    return doGet(`${member._links.depositStores.href}?query=${queryParam}`);
}

export function getDepositStoresByUserId(userId: string): Promise<DepositStore[]> {
    const url: string = absolute(`/v1/users/${userId}/deposit-stores`);

    return doGet(url);
}

export function searchDepositStoresByUserId(userId: string, queryParam: string): Promise<DepositStore[]> {
    const url: string = absolute(`/v1/users/${userId}/deposit-stores?query=${queryParam}`);

    return doGet(url);
}

export function getDepositStoreByUrl(url: string): Promise<DepositStore> {
    return doGet(url);
}

export function getDepositsByDepositStore(depositStore: DepositStore): Promise<Deposit[]> {
    return doGet(depositStore._links.deposits.href);
}

export function getDepositStoreHolders(depositStore: DepositStore): (() => Promise<Holder[]>) | undefined {
    const link = depositStore._links.holders;

    if (!link.available) {
        return undefined;
    }

    return () => doGet<Holder[]>(link.href);
}

export function removeDepositStoreContributor(user: UserInfo): (() => Promise<UserInfo>) | undefined {
    const link = user._links.removeUserManager;

    if (!link.available) {
        return undefined;
    }

    return () => doDelete(link.href);
}

export function addUserManagerToDepositStore(depositStore: DepositStore): ((userInfo: UserInfo) => Promise<void>) | undefined {
    const link = depositStore._links.addUserManager;

    if (!link.available) {
        return undefined;
    }

    return (userInfo: UserInfo) => doPost(link.href, { id: userInfo.id });
}

export function addMemberToDepositStore(depositStore: DepositStore): ((member: DepositMember) => Promise<void>) | undefined {
    const link = depositStore._links.addMemberManager;

    if (!link.available) {
        return undefined;
    }

    return (member: DepositMember) => doPost(link.href, { representativeId: member.id });
}

export function removeDepositStoreMember(depositMember: DepositMember): (() => Promise<void>) | undefined {
    const link = depositMember._links.removeMemberManager;

    if (!link.available) {
        return undefined;
    }

    return () => doDelete(link.href);
}

export function getManagedByDepositStore(depositStore: DepositStore): (() => Promise<ManagedBy>) | undefined {
    const link = depositStore._links.defaultManagedBy;

    if (!link?.available) {
        return undefined;
    }

    return () => doGet(link.href);
}
