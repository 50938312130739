import {
    ApisContext,
    BoxIcon,
    Card,
    Credits,
    DepositMember,
    DepositStats,
    DepositStore,
    getDepositStatsByUserId,
    getDepositStoresByUserId,
    getMemberApi,
    getMemberCredit,
    getMemberDetails,
    getOwnCredits,
    getRepresentativesByMember,
    GridCell,
    InfoTracker,
    LetterClose,
    Loader,
    Member,
    MemberApi,
    MemberCredit,
    MoneyIcon,
    PersonsIcon,
    Separator,
    ShareIcon,
    SkeletonCards,
    useApi,
    useI18n,
    User,
    UserContext
} from "@vaultinum/app-sdk";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Translation } from "../../../i18n";
import {
    CONTACT_SUPPORT,
    CREDIT_BUY_PAGE,
    CREDIT_PAGE,
    DEPOSIT_PAGE,
    HELP_PAGE,
    MEMBERS_ADVANTAGES,
    toDepositCreatorsPage,
    toDepositStorePage,
    WALLET_PAGE
} from "../../../services";
import { DepositCreatorsTabs } from "../DepositCreatorsPage";

const DepositMemberSection = ({ label, members }: { label: string; members: DepositMember[] }) => {
    return (
        <>
            <Separator label={label} />
            <ul className="flex flex-col gap-y-2.5">
                {members
                    .map(member => (
                        <li key={member.id}>
                            <Card title={<span className="font-bold" children={member.name} />} />
                        </li>
                    ))
                    .slice(0, 2)}
            </ul>
        </>
    );
};

const DepositMemberList = ({ depositMembers }: { depositMembers: DepositMember[] }) => {
    const { translation } = useI18n<Translation>();
    const mandates = depositMembers?.filter(rep => rep.request.type === DepositMember.Type.MANDATE);
    const representatives = depositMembers?.filter(rep => rep.request.type === DepositMember.Type.REPRESENTATIVE);

    return (
        <>
            {mandates?.length === 0 && representatives?.length === 0 && <p className="text-color-disabled text-sm">{translation.noExternalDepositMembers}</p>}
            {mandates?.length > 0 && (
                <DepositMemberSection label={translation.depositMembersCount(mandates.length, DepositMember.Type.MANDATE)} members={mandates} />
            )}
            {representatives?.length > 0 && (
                <DepositMemberSection
                    label={translation.depositMembersCount(representatives.length, DepositMember.Type.REPRESENTATIVE)}
                    members={representatives}
                />
            )}
        </>
    );
};

export default function HomePage() {
    const apis = useContext(ApisContext);
    const { translation, translate } = useI18n<Translation>();
    const { user } = useContext(UserContext);
    const [userCredits, setUserCredits] = useState<Credits>();
    const [memberInfos, setMemberInfos] = useState<Member | undefined>();
    const [depositStores, setDepositStores] = useState<DepositStore[]>();
    const [depositStoreInfo, setDepositStoreInfo] = useState<DepositStats>();
    const [memberCredits, setMemberCredits] = useState<MemberCredit>();
    const [depositMembers, setDepositMembers] = useState<DepositMember[]>();
    const isDepositmembers = depositMembers && depositMembers.length > 0;
    const isDepositStores = depositStores && depositStores.length > 0;

    const { fetchApi } = useApi();

    const userName = `${user?.firstName} ${user?.lastName}`;
    const isAdmin = (): boolean => user?.roles.includes(User.Role.ADMIN) ?? false;
    const creditToDisplay = isAdmin() ? memberCredits?.available : userCredits?.available;

    const loadInfos = async () => {
        const memberApi = await fetchApi<MemberApi>(getMemberApi);

        if (memberApi) {
            const memberFn = getMemberDetails(memberApi);

            if (memberFn) {
                const infos = await fetchApi(memberFn);
                setMemberInfos(infos);
                if (infos) {
                    const fetchRepresentatives = getRepresentativesByMember(infos);

                    if (fetchRepresentatives) {
                        await fetchApi(fetchRepresentatives, setDepositMembers);
                    }
                }
            }
        }
        const getOwnCreditsFn = getOwnCredits(apis.deposit);

        if (getOwnCreditsFn) {
            setUserCredits(await getOwnCreditsFn());
        }

        if (user?.sub) {
            const depositStoresResponse = await fetchApi(() => getDepositStoresByUserId(user?.sub));
            setDepositStores(depositStoresResponse);
            const depositStats = await fetchApi(() => getDepositStatsByUserId(user?.sub));
            setDepositStoreInfo(depositStats);
        }

        if (memberApi && isAdmin()) {
            const memberFn = getMemberDetails(memberApi);
            if (memberFn) {
                await fetchApi(memberFn, async (response: Member) => {
                    const creditResponse = await fetchApi(() => getMemberCredit(response.id));
                    setMemberCredits(creditResponse);
                });
            }
        }
    };

    useEffect(() => {
        void loadInfos();
    }, [user]);

    if (!memberInfos || !userCredits || !depositStoreInfo || !depositMembers) {
        return <Loader />;
    }

    const displayCredits = () => {
        if (isAdmin() && memberCredits) {
            return memberCredits.available;
        }

        return userCredits.available;
    };

    const displayExpiredCredits = () => {
        const credits = isAdmin() ? memberCredits?.expiresAt : userCredits.expiresAt;
        return translation.validUntil(credits || "-");
    };

    return (
        <>
            <div className="flex flex-col">
                <div className="flex justify-between">
                    <h1 className="m-0 text-2xl font-light">{translation.dahsboardOwner(memberInfos.name)}</h1>
                    <p className="text-label">
                        {translation.informationRenewSubscription(memberInfos?.subscriptionRenewalDate ?? translation.informationNotAvailableYet)}
                    </p>
                </div>
                <p className="text-label">{translation.welcomeOwner(userName)}</p>
            </div>
            <div className="grid grid-cols-[1fr] lg:grid-cols-[repeat(6,_1fr)_22rem] gap-5">
                {/* GAUCHE */}
                <div className="grid grid-cols-1 lg:grid-cols-2 col-span-6 gap-5">
                    <div className="grid gap-5 auto-rows-min">
                        <GridCell
                            title={translation.ownerDeposit(memberInfos)}
                            primaryButtonProps={{
                                label: translation.depositAction,
                                to: DEPOSIT_PAGE
                            }}
                            icon={<BoxIcon className="w-5 h-5 fill-primary" />}
                            alignButtons="center"
                        >
                            <InfoTracker figure={{ value: depositStoreInfo.depositCount, label: translation.deposits }} />
                            <InfoTracker figure={{ value: depositStoreInfo.depositStoreCount, label: translation.depositStore.names }} />
                        </GridCell>
                        <GridCell
                            title={translation.yourExternalContributors}
                            icon={<PersonsIcon className="w-5 h-5 fill-primary" />}
                            primaryButtonProps={
                                !isDepositmembers
                                    ? undefined
                                    : {
                                          label: translation.seeMore,
                                          to: toDepositCreatorsPage(DepositCreatorsTabs.TIERS)
                                      }
                            }
                            tooltipMessage={translation.tooltipCollaborator}
                        >
                            <DepositMemberList depositMembers={depositMembers} />
                        </GridCell>
                    </div>
                    <div className="grid gap-5 auto-rows-min">
                        <GridCell
                            title={translation.previewLastDepositStores}
                            subtitle={translate(translation.depositStoreCount, depositStoreInfo.depositStoreCount)}
                            icon={<ShareIcon className="w-5 h-5 fill-primary" />}
                            primaryButtonProps={
                                !isDepositStores
                                    ? undefined
                                    : {
                                          label: translation.seeMore,
                                          to: WALLET_PAGE
                                      }
                            }
                        >
                            <ul className="flex flex-col space-y-1.5">
                                {!depositStores && <SkeletonCards length={4} />}
                                {depositStores?.length === 0 && <p>{translation.createYourFirstDeposit}</p>}
                                {depositStores
                                    ?.map(depositStore => (
                                        <li key={depositStore.id}>
                                            <Link to={toDepositStorePage(depositStore)}>
                                                <Card
                                                    icon={<LetterClose className="w-4 h-4 fill-primary" />}
                                                    title={depositStore.name}
                                                    subTitle={depositStore.iddn}
                                                />
                                            </Link>
                                        </li>
                                    ))
                                    .slice(0, 4)}
                            </ul>
                        </GridCell>
                        <GridCell
                            title={translation.soldCredits}
                            subtitle={displayExpiredCredits()}
                            primaryButtonProps={
                                creditToDisplay === 0
                                    ? undefined
                                    : {
                                          label: translation.manage,
                                          to: CREDIT_PAGE
                                      }
                            }
                            secondaryButtonProps={{
                                label: "Acheter des crédits",
                                to: CREDIT_BUY_PAGE
                            }}
                            icon={<MoneyIcon className="w-5 h-5 fill-primary" />}
                            alignTitle="center"
                        >
                            <InfoTracker
                                figure={{
                                    value: displayCredits(),
                                    label: translation.available
                                }}
                            />
                        </GridCell>
                    </div>
                </div>

                {/* DROITE */}
                <div className="grid grid-cols-1 col-span-6 lg:col-span-1 gap-5 auto-rows-min border-l-4 border-disabled pl-5">
                    <GridCell
                        customClass="row-span-2 text-label relative"
                        title={translation.appAnnouncement}
                        primaryButtonProps={{
                            label: translation.knownYourAvantage,
                            to: MEMBERS_ADVANTAGES,
                            shouldOpenInNewTab: true
                        }}
                        children={<p>{translation.avantageAppMember}</p>}
                    />
                    <GridCell
                        customClass="row-span-1 text-label"
                        title={translation.needHelp}
                        primaryButtonProps={{
                            label: translation.contactHelpDesk,
                            to: CONTACT_SUPPORT,
                            shouldOpenInNewTab: true
                        }}
                        children={translation.widgetContact}
                    />
                    <GridCell
                        customClass="row-span-2 text-label"
                        title={translation.helpPage}
                        primaryButtonProps={{
                            label: translation.goTohelpPage,
                            to: HELP_PAGE,
                            shouldOpenInNewTab: true
                        }}
                        children={<p>{translation.widgetHelp}</p>}
                    />
                </div>
            </div>
        </>
    );
}
