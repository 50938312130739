import classNames from "classnames";
import React, { ReactNode } from "react";

export type CardBoxItem = {
    value: string;
    content: ReactNode | string[];
    title?: string;
    header?: ReactNode;
    subtitle?: string;
    helperText?: string;
    bottomLeftContent?: ReactNode;
    bottomRightContent?: ReactNode;
    isBordered?: boolean;
    checked?: boolean;
    disabled?: boolean;
    lightMode?: boolean;
    position?: "left" | "right" | "center";
    topRightContent?: ReactNode;
};

export function CardBoxContent({
    content,
    title,
    header,
    subtitle,
    helperText,
    bottomLeftContent,
    bottomRightContent,
    checked,
    isDisabled,
    lightMode,
    position = "left"
}: {
    content: ReactNode | string[];
    title?: string;
    header?: ReactNode;
    subtitle?: string;
    helperText?: string;
    bottomLeftContent?: ReactNode;
    bottomRightContent?: ReactNode;
    isBordered?: boolean;
    checked?: boolean;
    isDisabled?: boolean;
    lightMode?: boolean;
    position?: "left" | "right" | "center";
}) {
    return (
        <div
            className={classNames("relative flex flex-col h-full", {
                "items-center": position === "center",
                "items-start": position === "left",
                "items-end": position === "right"
            })}
        >
            <div
                className={classNames("flex flex-col mb-4", {
                    "items-center": position === "center",
                    "items-start": position === "left",
                    "items-end": position === "right"
                })}
            >
                {header}
                <h2
                    className={classNames("font-bold text-base", {
                        "text-primary": checked || (!checked && !isDisabled),
                        "text-light-black": isDisabled,
                        "font-light text-light-black": lightMode,
                        "text-center": position === "center",
                        "text-left": position === "left",
                        "text-right": position === "right"
                    })}
                >
                    {title}
                    {subtitle && (
                        <p
                            className={classNames("text-sm font-light -mt-1", {
                                "text-primary": checked || (!checked && !isDisabled),
                                "text-light-black": isDisabled || lightMode
                            })}
                        >
                            {subtitle}
                        </p>
                    )}
                </h2>
            </div>
            <div
                className={classNames("text-sm flex flex-col", {
                    "text-label": checked,
                    "text-light-black": isDisabled || lightMode,
                    "text-center": position === "center",
                    "text-left": position === "left",
                    "text-right": position === "right"
                })}
            >
                {helperText && <p className="text-sm">{helperText}</p>}
                {content && Array.isArray(content) ? (
                    content.map((item, index) => (
                        <React.Fragment key={index}>
                            <p>{item}</p>
                            {index !== content.length - 1 && <br />}
                        </React.Fragment>
                    ))
                ) : (
                    <>{content}</>
                )}
            </div>
            <footer
                className={classNames("flex w-full items-end mt-auto pt-4", {
                    "justify-between": bottomLeftContent && bottomRightContent,
                    "justify-end": !bottomLeftContent && bottomRightContent,
                    "justify-start": bottomLeftContent && !bottomRightContent
                })}
            >
                {bottomLeftContent}
                {bottomRightContent}
            </footer>
        </div>
    );
}
