import { LayoutSide, LayoutSideElement } from "../../../design-system";
import { canSendRequest } from "../../helpers";
import { Translation, useI18n } from "../../i18n";
import { DepositMember, Member } from "../../model";

export default function RepresentativesSide({
    toRequestForm,
    member,
    depositMembers
}: {
    toRequestForm: () => void;
    member: Member;
    depositMembers?: DepositMember[];
}): JSX.Element {
    const { translation, translate } = useI18n<Translation>();

    const representativeStatus = (): LayoutSideElement[] => {
        const status: LayoutSideElement[] = [];

        if (depositMembers) {
            const activeStatus = depositMembers.filter(depositMember => depositMember.request.status === DepositMember.Status.ACCEPTED);
            const refusedStatus = depositMembers.filter(depositMember => depositMember.request.status === DepositMember.Status.REFUSED);
            const sentStatus = depositMembers.filter(depositMember => depositMember.request.status === DepositMember.Status.SENT);

            if (activeStatus.length) {
                status.push({ label: translate(translation.representativesActiveCount, activeStatus.length) });
            }

            if (refusedStatus.length) {
                status.push({ label: translate(translation.representativesRefusedCount, refusedStatus.length) });
            }

            if (sentStatus.length) {
                status.push({ label: translate(translation.representativesSentCount, sentStatus.length) });
            }
        }

        return status;
    };

    return (
        <>
            <LayoutSide title={translation.representatives} sideElements={representativeStatus()} />
            <LayoutSide
                title={translation.actions}
                sideElements={[
                    {
                        label: translation.createInvitation,
                        elementType: "button",
                        action: toRequestForm,
                        disabled: !canSendRequest(member)
                    }
                ]}
            />
        </>
    );
}
