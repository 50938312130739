import { IncrementInput, Product, useI18n } from "@vaultinum/app-sdk";
import classNames from "classnames";
import { ReactNode } from "react";

import { Translation } from "../../i18n";

const OrderItemBase = ({
    productName,
    productPrice,
    quantity,
    onQuantityChange,
    incrementDisabled,
    pulse
}: {
    productName: ReactNode;
    productPrice: ReactNode;
    quantity: number;
    onQuantityChange: (newQuantity: number) => void;
    incrementDisabled?: boolean;
    pulse?: boolean;
}): ReactNode => {
    const { translation } = useI18n<Translation>();

    return (
        <div
            className={classNames("flex items-center py-2 px-4 w-full gap-2.5 bg-white hover:bg-disabled border-2 rounded-lg shadow", {
                "animate-pulse": pulse,
                "border-primary": !!quantity
            })}
        >
            <span className="flex items-center text-label text-sm text-left font-semibold grow h-full">{productName}</span>
            <IncrementInput disabled={incrementDisabled} onChange={onQuantityChange} value={quantity} min={0} />
            <div className="flex flex-col items-end">
                <span className="text-xs font-light text-color-disabled">{translation.unitPriceVatExcluded}</span>
                <span className="font-bold text-primary">{productPrice}</span>
            </div>
        </div>
    );
};

export function OrderItem({
    product,
    quantity,
    onQuantityChange
}: {
    product: Product;
    quantity: number;
    onQuantityChange: (newQuantity: number) => void;
}): ReactNode {
    const { translation } = useI18n<Translation>();

    return (
        <OrderItemBase
            productName={product.name}
            productPrice={translation.formatPrice(product.unitPriceVatExcluded)}
            quantity={quantity}
            onQuantityChange={onQuantityChange}
        />
    );
}

OrderItem.displayName = "OrderItem";

const OrderItemSkeleton = (): ReactNode => (
    <OrderItemBase
        productName={<div className="h-5 w-40 bg-gray-200 rounded"></div>}
        productPrice={<div className="h-6 w-20 bg-gray-200 rounded"></div>}
        quantity={0}
        onQuantityChange={() => {}}
        incrementDisabled={true}
        pulse={true}
    />
);

OrderItemSkeleton.displayName = "OrderItem.Skeleton";

OrderItem.Skeleton = OrderItemSkeleton;
