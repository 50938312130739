import { ChangeEvent, ReactNode } from "react";
import { Box } from "..";

export default function RadioBoxCard({
    children,
    value,
    checked,
    onChange,
    topRightContent,
    disabled,
    scrollOnClick
}: {
    children: ReactNode;
    value: string;
    checked: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    topRightContent?: ReactNode;
    disabled?: boolean;
    scrollOnClick?: boolean;
}) {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (scrollOnClick) {
            window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
        }
        onChange(e);
    };

    return (
        <label htmlFor={value}>
            <Box checked={checked} isClickable disabled={disabled} isBordered>
                {topRightContent && <div className="absolute top-1.5 right-2.5">{topRightContent}</div>}
                {children}
                <input
                    id={value}
                    type="radio"
                    value={value}
                    checked={checked}
                    disabled={disabled}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    className="sr-only"
                />
            </Box>
        </label>
    );
}
