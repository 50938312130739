import { createContext, ReactNode, useMemo } from "react";
import { RestApiMemberService } from "../services/rest-api-member.service";
import { MemberService } from "../services/new-member.service";

interface ServiceContextInterface {
    memberService: MemberService;
}

export const ServiceContext = createContext<ServiceContextInterface>({} as ServiceContextInterface);

export function RestApiServiceContextProvider({ children }: { children: ReactNode }): JSX.Element {
    const exposed: ServiceContextInterface = useMemo(() => {
        if (!process.env.REACT_APP_MEMBER_API_URL) {
            throw new Error("REACT_APP_MEMBER_API_URL is not defined");
        }
        return {
            memberService: new RestApiMemberService(process.env.REACT_APP_MEMBER_API_URL)
        };
    }, []);

    return <ServiceContext.Provider value={exposed}>{children}</ServiceContext.Provider>;
}
