import classNames from "classnames";

import { Link, Pagination as PaginationModel } from "../../model";
import { doGet } from "../../services/api.service";

const PaginationPage = ({ pagination, page, onClick }: { pagination: PaginationModel; page: number; onClick: () => void }): JSX.Element => (
    <li
        className={classNames("flex items-center justify-center px-3 py-2 border-2 rounded cursor-pointer", {
            "bg-primary text-white border-primary hover:bg-primary hover:border-primary font-bold": page === pagination.currentPage,
            "bg-white border-disabled hover:bg-primary-light hover:border-primary-light": page !== pagination.currentPage
        })}
        onClick={onClick}
    >
        {page}
    </li>
);

const PaginationSeparation = (): JSX.Element => <li className="flex items-center justify-center px-3 py-2">&#8230;</li>;

export default function NavigationPagination<T>({
    pagination,
    onLoadingInitialized,
    onLoadingSuccess,
    onLoadingError
}: {
    pagination: PaginationModel;
    onLoadingInitialized?: () => void;
    onLoadingSuccess?: (result: T) => void;
    onLoadingError?: () => void;
}): JSX.Element {
    const first = pagination._links.first;
    const previous = pagination._links.previous;
    const self = pagination._links.self;
    const next = pagination._links.next;
    const last = pagination._links.last;

    const navigateToPage = async (link: Link) => {
        onLoadingInitialized?.();

        try {
            const result = await doGet<T>(link.href);

            onLoadingSuccess?.(result);
        } catch {
            onLoadingError?.();
        }
    };

    return (
        <ul className="flex justify-center space-x-2">
            <PaginationPage pagination={pagination} page={pagination.firstPage} onClick={() => navigateToPage(first)} />
            {pagination.currentPage - 1 > pagination.firstPage + 1 && <PaginationSeparation />}
            {previous && pagination.currentPage - 1 > pagination.firstPage && (
                <PaginationPage pagination={pagination} page={pagination.currentPage - 1} onClick={() => navigateToPage(previous)} />
            )}
            {pagination.currentPage > pagination.firstPage && pagination.currentPage < pagination.lastPage && (
                <PaginationPage pagination={pagination} page={pagination.currentPage} onClick={() => navigateToPage(self)} />
            )}
            {next && pagination.currentPage + 1 < pagination.lastPage && (
                <PaginationPage pagination={pagination} page={pagination.currentPage + 1} onClick={() => navigateToPage(next)} />
            )}
            {pagination.currentPage + 1 < pagination.lastPage - 1 && <PaginationSeparation />}
            {pagination.firstPage < pagination.lastPage && (
                <PaginationPage pagination={pagination} page={pagination.lastPage} onClick={() => navigateToPage(last)} />
            )}
        </ul>
    );
}
