import { ReactNode } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { Input, RadioFields } from "../../../../design-system";

export default function FormFieldsRadio({
    radioGroupName,
    control,
    options,
    helperText
}: {
    radioGroupName: string;
    control: Control<FieldValues>;
    options: RadioFields[];
    helperText?: ReactNode;
}) {
    return (
        <>
            <ul className="space-y-1.5">
                {options.map(option => (
                    <li key={option.value}>
                        <Controller
                            name={radioGroupName}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Input
                                    onChange={onChange}
                                    type="radio"
                                    fieldName={option.value}
                                    checked={option.value === value}
                                    value={option.value}
                                    disabled={option.disabled}
                                    label={option.title}
                                />
                            )}
                        />
                    </li>
                ))}
            </ul>
            {helperText}
        </>
    );
}
