import { DepositMember } from "../DepositMember";
import { Link } from "../Hateoas";

type Requester = {
    member: {
        name: string;
    };
    user: {
        firstName: string;
        lastName: string;
    };
};

export type Request = {
    type: DepositMember.Type;
    requester: Requester;
    _links: {
        accept: Link;
        reject: Link;
    };
};

export interface RequestSender {
    from: string;
    id: string;
    recipientEmail: string;
    requestedAt: string;
    status: RequestSender.Status;
    type: DepositMember.Type;
}

export namespace RequestSender {
    export enum Status {
        SENT = "SENT"
    }
}
