import { ReactNode } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";

import { Button, CardBoxContent, Info, RadioBoxCard, RadioFields } from "../../../../design-system";
import { Translation, useI18n } from "../../../i18n";

export default function FormFieldsRadioCard({
    radioGroupName,
    control,
    options,
    onClick,
    helperText,
    helperTextAction
}: {
    radioGroupName: string;
    control: Control<FieldValues>;
    options: RadioFields[];
    onClick: () => void;
    helperText?: string | ReactNode;
    helperTextAction?: ReactNode;
}) {
    const { translation } = useI18n<Translation>();
    return (
        <>
            <ul className="flex gap-2 items-stretch w-full">
                {options.map(option => (
                    <li key={option.value} className="w-full">
                        <Controller
                            name={radioGroupName}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <RadioBoxCard
                                    value={option.value}
                                    checked={option.value === value}
                                    onChange={onChange}
                                    topRightContent={option.topRightContent}
                                    disabled={option.disabled}
                                >
                                    <CardBoxContent
                                        checked={option.value === value}
                                        header={option.header}
                                        title={option.title}
                                        content={option.content}
                                        isDisabled={option.disabled}
                                        bottomLeftContent={<Button variant="link" label={translation.learnMore} onClick={onClick} />}
                                        isBordered={false}
                                    />
                                </RadioBoxCard>
                            )}
                        />
                    </li>
                ))}
            </ul>
            {helperText && (
                <div className="w-[45rem] pt-14">
                    <Info type="primary">
                        {helperText}
                        {helperTextAction && (
                            <>
                                <br />
                                {helperTextAction}
                            </>
                        )}
                    </Info>
                </div>
            )}
        </>
    );
}
