import { v4 as uuidv4 } from "uuid";

type Download = {
    name: string;
    blob: Blob;
};

const parseJson = async (response: Response) => {
    const text = await response.text();

    try {
        return JSON.parse(text);
    } catch {
        return text;
    }
};

const buildUrl = (url: string, queryParams?: Record<string, string>): string => {
    if (queryParams) {
        return `${url}?${new URLSearchParams(queryParams)}`;
    }

    return url;
};

async function doRequest<T>(
    url: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    callback: (response: Response) => T,
    headers?: { [key: string]: string },
    config?: { [key: string]: unknown }
): Promise<T> {
    const correlationId = uuidv4();
    const response = await fetch(url, {
        ...config,
        method,
        headers: {
            ...headers,
            "X-Correlation-Id": correlationId
        }
    });

    if (response.status > 299) {
        return Promise.reject({
            status: response.status,
            body: await response.text(),
            correlationId
        });
    }

    return callback(response);
}

async function doRequestJson<T>(
    url: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    headers?: { [key: string]: string },
    config?: { [key: string]: unknown }
): Promise<T> {
    return doRequest(url, method, parseJson, headers, config);
}

const isFormData = (obj?: { [key: string]: unknown } | FormData) => {
    return typeof obj?.append === "function";
};

export async function doGet<T>(url: string, queryParams?: Record<string, string>, headers?: { [key: string]: string }): Promise<T> {
    return doRequestJson(buildUrl(url, queryParams), "GET", headers);
}

export async function doPost<T>(url: string, body?: { [key: string]: unknown } | FormData, headers?: { [key: string]: string }): Promise<T> {
    return doRequestJson(url, "POST", headers, { body: isFormData(body) ? body : JSON.stringify(body) });
}

export async function doPut<T>(url: string, body?: { [key: string]: unknown } | FormData, headers?: { [key: string]: string }): Promise<T> {
    return doRequestJson(url, "PUT", headers, { body: isFormData(body) ? body : JSON.stringify(body) });
}

export async function doDelete<T>(url: string, body?: { [key: string]: unknown } | FormData, headers?: { [key: string]: string }): Promise<T> {
    return doRequestJson(url, "DELETE", headers, { body: isFormData(body) ? body : JSON.stringify(body) });
}

export async function download(url: string, queryParams?: Record<string, string>, headers?: { [key: string]: string }): Promise<Download> {
    return doRequest(
        buildUrl(url, queryParams),
        "GET",
        async response => {
            const filenameHeader = response.headers.get("Content-Disposition");

            if (!filenameHeader) {
                return Promise.reject("No filename given");
            }

            return {
                name: filenameHeader.replace("attachment; filename=", ""),
                blob: await response.blob()
            };
        },
        headers
    );
}
