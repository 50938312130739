import classNames from "classnames";
import { Card } from "../..";

export default function SkeletonCards({ small, length }: { small?: boolean; length: number }) {
    const Cards: JSX.Element[] = Array.from({ length }, (_, index) => (
        <li key={index}>
            <Card title="" isLoading isSmall={small} />
        </li>
    ));
    return <ul className={classNames({ "space-y-1": small, "space-y-2": !small })}>{Cards}</ul>;
}
