import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { DepositCard } from ".";
import { Button, ExpandButton, Info, Input, Popup, useToast } from "../../../design-system";
import { useApi } from "../../hooks";
import { Translation, useI18n } from "../../i18n";
import { Deposit, SealStatus } from "../../model";
import { deleteAllFiles, getDeleteDepositMetadataFn } from "../../services";

export default function DepositsList({
    deposits,
    navigateToWallet,
    refreshCallback,
    depositUpdateLinkGenerator,
    urlMemberPage,
    resumeCallback,
    showToolTip = true,
    copyable = false,
    displayCopyUuid = false
}: {
    deposits: Deposit[];
    navigateToWallet: () => void;
    refreshCallback: () => void;
    urlMemberPage?: string;
    depositUpdateLinkGenerator?: (deposit: Deposit) => string;
    resumeCallback?: (deposit: Deposit) => void;
    showToolTip?: boolean;
    copyable?: boolean;
    displayCopyUuid?: boolean;
}) {
    const [depositsFiltered, setDepositsFiltered] = useState<Deposit[]>([]);
    const [lastDeposit, setLastDeposit] = useState<Deposit>();
    const [depositsInProgress, setDepositsInProgress] = useState<Deposit[]>([]);
    const [oldDeposits, setOldDeposits] = useState<Deposit[]>([]);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [inputSearch, setInputSearch] = useState<string>("");
    const [searchParams, setSearchParams] = useSearchParams();
    const { translation } = useI18n<Translation>();
    const [openPopUp, setOpenPopUp] = useState<boolean>(false);
    const [depositToRemove, setDepositToRemove] = useState<Deposit | undefined>();
    const { fetchApi } = useApi();
    const notify = useToast();

    const isDeposit = depositsFiltered.length > 0 || oldDeposits.length > 0 || lastDeposit;

    const creatorUser = depositsInProgress[0]?.creator.user;

    const creatorMember = depositsInProgress[0]?.creator.member;

    const creatorInfos = creatorUser ? `${creatorUser.firstName} ${creatorUser.lastName}` : `${creatorMember?.name}`;

    const creatorRole = creatorUser?.role;

    const filter = searchParams.get("to");

    useEffect(() => {
        if (depositsFiltered.length > 0) {
            setOldDeposits(depositsFiltered.slice(1));
            setLastDeposit(depositsFiltered.shift());
        }
    }, [depositsFiltered]);

    useEffect(() => {
        if (deposits && deposits.length > 0) {
            setDepositsFiltered(deposits.filter(deposit => deposit.sealStatus !== SealStatus.OPEN));
            setDepositsInProgress(deposits.filter(deposit => deposit.sealStatus === SealStatus.OPEN));
        }
    }, [deposits]);

    useEffect(() => {
        if (filter) {
            const element = document.getElementById(filter);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    });

    const handleToggle = () => {
        if (searchParams.get("to")) {
            searchParams.delete("to");
            setSearchParams(searchParams);
        }

        setExpanded(prev => !prev);
    };

    const displayAlert = (deposit: Deposit) => {
        setOpenPopUp(true);
        setDepositToRemove(deposit);
    };

    const handleRemove = async () => {
        if (depositToRemove) {
            const deleteFn = getDeleteDepositMetadataFn(depositToRemove);
            if (deleteFn) {
                await fetchApi(deleteFn, () => notify.success(translation.depositCancelToast));

                if (deposits && deposits.length === 1) {
                    navigateToWallet();
                } else {
                    refreshCallback();
                }
            }
        }
        setOpenPopUp(false);
    };

    const hasCancelUpload = (depositsList: Deposit[]): boolean => {
        return depositsList.map(deleteAllFiles).some(fn => fn !== undefined);
    };

    return (
        <div className="space-y-5">
            {depositsInProgress.length > 0 && (
                <div className="space-y-2.5">
                    <Info type="warning">
                        <p>
                            {hasCancelUpload(depositsInProgress)
                                ? translation.infoInitialDepositUploadCanceled(creatorInfos, creatorRole)
                                : translation.infoInitialDepositInProgress(creatorInfos, creatorRole)}
                        </p>
                    </Info>
                    {depositsInProgress.map(deposit => (
                        <DepositCard
                            key={deposit.id}
                            deposit={deposit}
                            onLeftClick={displayAlert}
                            onRightClick={resumeCallback}
                            refreshCallback={refreshCallback}
                            showToolTip={showToolTip}
                            copyable={copyable}
                            displayCopyUuid={displayCopyUuid}
                            urlMemberPage={urlMemberPage}
                        />
                    ))}
                </div>
            )}

            {isDeposit && (
                <Input
                    label={translation.input.helperTextSearch}
                    onChange={e => setInputSearch(e.target.value)}
                    value={inputSearch}
                    helperText={translation.input.helperText}
                    type="search"
                    className="w-96"
                />
            )}

            {lastDeposit && (
                <div>
                    <p className="mb-2 font-semibold">{translation.deposit.lastDeposit}</p>
                    {
                        <DepositCard
                            deposit={lastDeposit}
                            depositUpdateLinkGenerator={depositUpdateLinkGenerator}
                            showToolTip={showToolTip}
                            copyable={copyable}
                            displayCopyUuid={displayCopyUuid}
                            urlMemberPage={urlMemberPage}
                        />
                    }
                </div>
            )}

            {oldDeposits && oldDeposits.length > 0 && (
                <div>
                    <div className="flex items-center justify-between">
                        <p className="mb-2 font-semibold">{translation.deposit.oldDepoists}</p>
                        <ExpandButton label={!expanded ? translation.expendAll : translation.collapseAll} onClick={handleToggle} />
                    </div>
                    <ul className="space-y-1.5">
                        {oldDeposits.map(oldDeposit => (
                            <li key={oldDeposit.id} id={oldDeposit.id}>
                                <DepositCard
                                    deposit={oldDeposit}
                                    open={filter === oldDeposit.id || expanded}
                                    depositUpdateLinkGenerator={depositUpdateLinkGenerator}
                                    showToolTip={showToolTip}
                                    copyable={copyable}
                                    displayCopyUuid={displayCopyUuid}
                                    urlMemberPage={urlMemberPage}
                                    isClosable
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <Popup
                isOpen={openPopUp}
                leftContent={<Button label={translation.goBack} onClick={() => setOpenPopUp(false)} />}
                rightContent={<Button label={translation.cancelDeposit.confirm} variant="danger" onClick={handleRemove} withLoader={true} />}
                title={translation.cancelDeposit.question}
            >
                <p className="text-base">{translation.cancelDeposit.message}</p>
            </Popup>
        </div>
    );
}
