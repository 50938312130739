import { AgreementType, Country, Deposit, DepositMember, DepositStore, Method, SealStatus, User } from "../../model";

const commonProperties = {
    iddn: "numéro iddn",
    subtitle: "sous titre de l'oeuvre",
    depositType: Deposit.Type.PHYSICAL,
    productClass: Deposit.ProductClass.DIGITAL_CREATION_2D,
    workType: Deposit.WorkType.COMPOSITE_WORK,
    workNature: Deposit.WorkNature.EXECUTABLE_SOFTWARE,
    workVersion: "1v",
    countryCode: Country.AN,
    remarks: "",
    logibox: {},
    member: {
        name: "Ben ltd",
        _links: {
            self: {
                href: "#"
            }
        }
    },
    creator: {
        member: {
            id: "creatorId",
            shortNumber: "1234",
            longNumber: "longNumber",
            name: "creatorName",
            role: Deposit.CreatorMemberRole.MAIN_HOLDER,
            _links: {
                self: {
                    href: "#"
                }
            }
        }
    },
    shortIddnNumber: "shortIddnNumber",
    name: "benLtd",
    createdAt: "2024-06-11",
    sealedAt: "2024-06-11",
    sealStatus: SealStatus.IN_PROGRESS,
    owner: {
        name: "ownerName"
    },
    _links: {
        self: {
            href: "#"
        },
        depositStore: {
            href: "#"
        },
        initializeUpload: {
            href: "#"
        },
        deleteAllFiles: {
            href: "#"
        },
        setSingleHolder: {
            href: "#"
        },
        setSharedHolders: {
            href: "#"
        },
        setRepresentative: {
            href: "#"
        }
    }
};

const DepositA = {
    ...commonProperties,
    id: "deposit1",
    depositStoreId: "depositStoreId",
    creator: {
        ...commonProperties.creator,
        member: {
            ...commonProperties.creator.member,
            id: "8"
        }
    },
    shortIddnNumber: "9"
};

const DepositB = {
    ...commonProperties,
    id: "10",
    depositStoreId: "11",
    creator: {
        ...commonProperties.creator,
        member: {
            ...commonProperties.creator.member,
            id: "12"
        }
    },
    shortIddnNumber: "13"
};

const DepositC = {
    ...commonProperties,
    id: "14",
    depositStoreId: "15",
    creator: {
        ...commonProperties.creator,
        member: {
            ...commonProperties.creator.member,
            id: "16"
        }
    },
    shortIddnNumber: "17"
};

const DepositD = {
    ...commonProperties,
    id: "18",
    depositStoreId: "19",
    creator: {
        ...commonProperties.creator,
        member: {
            ...commonProperties.creator.member,
            id: "20"
        }
    },
    shortIddnNumber: "21"
};

export const mockData: DepositStore[] = [
    {
        id: "1",
        name: "Deposit store created by representative test avec un long titre de dépot pour voir ce que ça pourrait donner",
        iddn: "IDDN.FR.001.520010.000.S.P.2023.000.10000",
        sealStatus: SealStatus.SEALED,
        createdAt: "12/06/2023",
        updatedAt: "02/08/2024",
        depositsCount: 2,
        holdersCount: 1,
        creator: {
            member: {
                role: Deposit.CreatorMemberRole.REPRESENTATIVE
            }
        },
        deposits: [DepositA],
        managedBy: {
            users: [
                {
                    id: "2",
                    firstName: "John Doe",
                    lastName: "lastname",
                    role: User.UserWalletRole.ADMINISTRATOR,
                    email: "johndoe@email.fr",
                    active: true,
                    _links: {
                        allocateCredits: {
                            href: "#"
                        },
                        removeUserManager: {
                            href: "#"
                        },
                        disable: {
                            href: "#"
                        },
                        enable: {
                            href: "#"
                        }
                    }
                },
                {
                    id: "3",
                    firstName: "User contributors",
                    lastName: "lastname",
                    role: User.UserWalletRole.CONTRIBUTOR,
                    email: "contributors@email.fr",
                    active: true,
                    _links: {
                        allocateCredits: {
                            href: "#"
                        },
                        removeUserManager: {
                            href: "#"
                        },
                        disable: {
                            href: "#"
                        },
                        enable: {
                            href: "#"
                        }
                    }
                }
            ],
            members: [
                {
                    id: "4",
                    fullNumber: "1432",
                    owner: {
                        firstName: "jean",
                        lastName: "dupont",
                        email: "jean.dupont@outlook.fr"
                    },
                    request: {
                        status: DepositMember.Status.ACCEPTED,
                        requestedAt: "2024-06-11",
                        type: DepositMember.Type.MANDATE
                    },
                    name: "Mandataire",
                    _links: {
                        removeMemberManager: {
                            href: "#"
                        }
                    }
                }
            ],
            isSharedToRepresentatives: true
        },
        _links: {
            self: {
                href: "#"
            },
            deposits: {
                href: "#"
            },
            addDeposit: {
                href: "#"
            },
            holders: {
                href: "#",
                method: Method.GET,
                available: true
            },
            addUserManager: {
                href: "#",
                method: Method.POST,
                available: true
            },
            addMemberManager: {
                href: "#",
                method: Method.POST,
                available: true
            }
        }
    },
    {
        id: "5",
        name: "Simple deposit store with holders",
        iddn: "",
        sealStatus: SealStatus.OPEN,
        createdAt: "04-06-2023",
        updatedAt: "27/06/2024",
        depositsCount: 3,
        holdersCount: 2,
        creator: {
            member: {
                role: Deposit.CreatorMemberRole.MAIN_HOLDER
            }
        },
        deposits: [DepositA, DepositB],
        managedBy: {
            users: [
                {
                    id: "6",
                    firstName: "John Doe",
                    lastName: "lastname",
                    role: User.UserWalletRole.ADMINISTRATOR,
                    email: "johndoe@email.fr",
                    active: true,
                    _links: {
                        allocateCredits: {
                            href: "#"
                        },
                        removeUserManager: {
                            href: "#"
                        },
                        disable: {
                            href: "#"
                        },
                        enable: {
                            href: "#"
                        }
                    }
                }
            ],
            members: [],
            isSharedToRepresentatives: false
        },
        _links: {
            self: {
                href: "#"
            },
            deposits: {
                href: "#"
            },
            addDeposit: {
                href: "#"
            },
            holders: {
                href: "#",
                method: Method.GET,
                available: true
            },
            addUserManager: {
                href: "#",
                method: Method.POST,
                available: true
            },
            addMemberManager: {
                href: "#",
                method: Method.POST,
                available: true
            }
        }
    },
    {
        id: "7",
        name: "test",
        iddn: "IDDN.FR.001.460007.000.S.P.2023.000.10000",
        sealStatus: SealStatus.SEALED,
        createdAt: "07-08-2024",
        updatedAt: "20/06/2024",
        depositsCount: 5,
        managedBy: {
            users: [],
            members: [],
            isSharedToRepresentatives: false
        },
        agreementTypes: [AgreementType.ACCESS_CLAUSE_STANDARD],
        agreements: [
            {
                reference: "string",
                type: AgreementType.ACCESS_CLAUSE_PREMIUM,
                canceled: false
            }
        ],
        deposits: [DepositA, DepositB, DepositC, DepositD],
        holdersCount: 1,
        creator: {
            member: {
                role: Deposit.CreatorMemberRole.MAIN_HOLDER
            }
        },
        _links: {
            self: {
                href: "#"
            },
            deposits: {
                href: "#"
            },
            addDeposit: {
                href: "#"
            },
            holders: {
                href: "#",
                method: Method.GET,
                available: true
            },
            addUserManager: {
                href: "#",
                method: Method.POST,
                available: true
            },
            addMemberManager: {
                href: "#",
                method: Method.POST,
                available: true
            }
        }
    }
];
