import {
    AuthenticationError,
    bytesConverter,
    CopyableText,
    CustomLink,
    Deposit,
    DepositMember,
    Environment,
    getEnvironment,
    Member,
    Request,
    fr as sharedFr,
    standardFormatDate,
    TickIcon
} from "@vaultinum/app-sdk";
import dayjs from "dayjs";
import { ReactNode } from "react";

import { ADD_HOLDERS_V1, BUY_CREDITS_V1, CONTACT_SUPPORT, ESCROW_AGREEMENT } from "../../services";
import { Translation } from "../Translation";

const APPLICATION_BADGES: { [env in Environment]: string } = {
    DEVELOPMENT: "EXTRANET - DEV",
    PRODUCTION: "EXTRANET - PROD",
    STAGING: "EXTRANET - STAGING"
};

const INVITATION_TYPE: { [key in DepositMember.Type]: string } = {
    REPRESENTATIVE: "Mandataire",
    MANDATE: "Mandant",
    COTITULARITY: "Cotitulaire",
    CONTRIBUTOR: "Contributeur",
    HOLDER: "Bénéficiaire"
};

const supportMessage = (title: string, subject: string, reference?: string): ReactNode => (
    <>
        <p className="font-bold">{title}</p>
        <p>
            Veuillez contacter le support{" "}
            {reference ? (
                <>
                    avec la référence <CopyableText value={reference} />
                </>
            ) : (
                ""
            )}{" "}
            :
        </p>
        <ul>
            <li>- Par téléphone : 01 40 35 03 03</li>
            <li>
                - Par mail : <a href={`mailto:app@app.asso.fr?subject=${subject}`}>app@app.asso.fr</a>
            </li>
        </ul>
    </>
);

const formatNumber = (n: number): string => n.toLocaleString("fr-FR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const fr: Translation = {
    ...sharedFr,
    login: "Identifiant",
    loginHelper:
        "Champ obligatoire. L'identifiant fait 12 caractères et est communiqué à l'issue de la procédure d'activation d'un compte en ligne. Cet identifiant n'est ni un e-mail ni un numéro de membre.",
    loginMemberSite: "Connexion",
    password: "Mot de passe",
    requiredField: "champs obligatoires",
    loginFail: {
        temporaryLocked: "Compte temporairement vérouillé",
        lockedUntil: (date: number) =>
            `Suite à de multiples tentatives de connexion, l'accès au compte est temporairement vérrouillé jusqu'au ${dayjs(date).format("DD/MM/YYYY HH:mm")}`,
        impossibleToConnect: "Impossible de se connecter",
        triesWarning: (error: AuthenticationError) =>
            `Tentative ${error.triesCount}/${error.triesCountMax}. Cette combinaison identifiant et mot de passe est incorrecte.`,
        accountLocked: (error: AuthenticationError) =>
            `Tentative ${error.triesCountMax}/${
                error.triesCountMax
            }. Suite à de multiples tentatives de connexion, l'accès au compte est temporairement verrouillé pour une durée de ${
                (error.accountLockDurationInMinutes || 0) / 60
            }h jusqu'au ${dayjs(error.accountLockedUntil).format("DD/MM/YYYY HH:mm")}`
    },
    connect: "Se connecter",
    forgottenPassword: "Mot de passe oublié ?",
    becomeMember: "Devenir membre",
    legalNotices: "Mentions légales",
    contactApp: "Contacter l'APP",
    resetPassword: {
        label: "Réinitialiser un mot de passe",
        description:
            "La procédure de réinitialisation du mot de passe se déroule en 3 étapes et doit être sollicitée par le contact Administrateur de votre compte en ligne.",
        requestCode: "Demande d'un code de réinitialisation",
        fillForm: "Renseigner et répondre au formulaire ci dessous.",
        getCodeByMail: "Réception d'un code de réinitialisation par e-mail",
        getCodeByMailDescription:
            "Le contact administrateur reçoit par email une url de réinitialisation de mot de passe valable 2 heures. Si vous n'êtes pas l'administrateur, contactez le dès à présent.",
        securityQuestionsAnswers: "Réponses aux questions de sécurité",
        cancelAndBackToLoginPage: "Annuler et retourner à la page de connexion",
        helperMessage:
            "Cliquer sur l'url de réinitialisation et répondre aux questions de sécurité. Si vous avez oublié les réponses à vos questions de sécurité, le contact administateur doit entrer en contact avec l'APP via"
    },
    disconnect: "Se déconnecter",
    applicationTitles: {
        DEVELOPMENT: "APP - Extranet DEV",
        PRODUCTION: "APP - Interdeposit",
        STAGING: "APP - Extranet STAGING"
    },
    applicationBadge: `${APPLICATION_BADGES[getEnvironment()]} ${process.env.REACT_APP_VERSION}`,
    depositAction: "Réaliser un dépôt",
    infoWarningUpload: (
        <>
            <p>
                <strong>Attention : </strong>En cliquant sur le bouton &quot;Valider et transmettre à l&apos;APP&quot; ci-dessous, les informations communiquées
                et vos fichiers attachés seront envoyés à l&apos;APP.
            </p>
            <p>
                Pour le bon déroulement du processus, <strong>veuillez ne pas recharger la page ou la quitter pendant le transfert.</strong>
            </p>
            <br />
            <p>Vous serez automatiquement redirigé vers une page de confirmation à la fin de la transmission.</p>
        </>
    ),
    saveAndQuit: "Sauvegarder cette étape et quitter",
    dahsboardOwner: (owner: string) => (
        <>
            Tableau de bord <span className="font-bold">{owner}</span>
        </>
    ),
    welcomeOwner: (owner: string) => `Bienvenue ${owner}`,
    depositSavingAlert: (name: string) => (
        <>
            Le dépôt temporaire <strong>&quot;{name}&quot;</strong> a été créé avec succès et est maintenant accessible depuis votre portefeuille. Vous pouvez
            reprendre votre travail à tout moment, là où vous l&apos;avez laissé.
        </>
    ),
    depositCreateAlert: (name: string) => (
        <>
            Un dépôt temporaire <strong>&quot;{name}&quot;</strong> a été créé avec succès et est désormais accessible depuis votre portefeuille. Toutes les
            informations que vous avez saisies jusqu&apos;à présent ont été sauvegardées. Si nécessaire, vous pouvez quitter en toute sécurité et reprendre
            votre travail ultérieurement.
        </>
    ),
    informationRenewSubscription: (date: string) => (
        <>
            Votre abonnement APP sera renouvelé le : <span className="font-bold text-primary">{standardFormatDate(date)}</span>
        </>
    ),
    allDeposits: "Dépôts du membre",
    allDepositsToolTip:
        "L'administrateur peut réaliser des dépôts ou déléguer cette tâche à ses contributeurs. Dans tous les cas, pour réaliser un dépôt, il faut disposer d'au moins un crédit de dépôt disponible",
    days: "jours",
    the: "Le",
    daysAgo: "Il y a",
    by: "Dernier déposant",
    walletOfTheAccount: "Tous les dépôts",
    firstDepositDate: "Premier dépôt le",
    lastDepositDate: "Dernier dépôt le",
    lastDepositTimeAgo: "Dernier il y a",
    lastDepositor: "Dernier déposant",
    lastContributor: "Dernier contributeur",
    yourDeposits: "Vos dépôts",
    soldCredits: "Solde de crédits",
    soldCreditsTooltip:
        "Pour faire un dépôt vous devez disposer d'au moins un crédit de dépôt. Seul votre administrateur peut acheter des crédits de dépôt et en allouer.",
    buyCredits: "Acheter des crédits",
    available: "disponibles",
    soldAvailableUntil: "Solde valable jusqu'au",
    validUntil: (date: string) => `Valable jusqu'au ${standardFormatDate(date)} inclus`,
    remainingDays: "Jours restants",
    depositStoreExtract: "Filières les plus récentes",
    depositStoreExtractTooltip:
        "Une filière de dépôts correspond à tous les dépôts d'une même création et permet d'attester des évolutions de cette dernière. Une filière débute dès qu'un dépôt initial est réalisé et se prolonge par des dépôts à titre de mises à jour. Chaque dépôt se voit attribuer un numéro IDDN unique mais possédant un chainage logique avec les numéros des précédents dépôts afin de créer une filiation entre les dépôts et de réunir l'ensemble des dépôts d'une même création au sein d'une seule filière.",
    depositsInWallet: (nbDepositStore: number, nbDeposits: number) => `${nbDepositStore} filières au total dans le portefeuille, dont ${nbDeposits} dépôts`,
    entiercementContract: "Entiercements",
    noEntiercementContract: "Aucun contrat d'entiercement en cours",
    appAnnouncement: "Avantages membres APP",
    knownYourAvantage: "Découvrir vos avantages",
    needHelp: "Besoin d'un conseil ou d'être accompagné ?",
    contactHelpDesk: "Nous écrire",
    widgetContact: (
        <>
            <p>Vous avez une question sur vos dépôts, vos droits, un doute, une demande spécifique ?</p>
            <p>Contactez nos conseillers du lundi au vendredi de 9h30 à 13h et de 14h à 17h30.</p>
            <div>
                <p>
                    Téléphone :{" "}
                    <a className="font-bold" href="tel:+33140350303">
                        +33 (0)1 40 35 03 03
                    </a>
                </p>
                <p>
                    Courriel :{" "}
                    <a className="font-bold" href="mailto:app@app.asso.fr">
                        app@app.asso.fr
                    </a>
                </p>
            </div>
        </>
    ),
    avantageAppMember: "En tant que membre APP vous bénéficiez d'avantages exclusifs. N'oubliez pas d'en profiter !",
    helpPage: "Aide en ligne et centre d'information",
    goTohelpPage: "Aide en ligne",
    goToInfoCenter: "Centre d'information",
    widgetHelp: (
        <p>
            Nous mettons à votre disposition une aide en ligne et un centre d&apos;information (base de connaissances, livres blancs, replay de nos webinars,
            etc.). N&apos;hésitez pas à les consulter&nbsp;!
        </p>
    ),
    errorHandling: (err: number) => supportMessage(`${err} - ${fr.httpCodes[err] || "Erreur inconnue"}`, `Erreur extranet ${err}`),
    httpErrorHandling: ({ status, correlationId }: { status: number; correlationId: string }) =>
        supportMessage(`${status} - ${fr.httpCodes[status] || "Erreur inconnue"}`, `Erreur extranet ${status} - réf. ${correlationId}`, correlationId),
    genericError: (message: string): ReactNode => supportMessage(message, `Erreur extranet - ${message}`),
    memberNumber: (member: Member) => `MEMBRE APP N° : ${member.fullNumber}`,
    news: "Actualités",
    discoverOurNews: "Retrouvez nos actualités",
    escrowContractTooltip: (
        <>
            L&apos;entiercement est un accord conclu entre le membre APP et un tiers (un client, un partenaire, etc.) qui stipule que ce tiers (le bénéficiaire
            de l&apos;accès), pourra avoir accès aux éléments déposés à l&apos;APP selon des conditions définies par cet accord et que le bénéficiaire pourra
            effectuer une demande d&apos;accès directement auprès de l&apos;APP (i.e. obtenir, après avis favorable de la commission d&apos;accès de l&apos;APP,
            une copie du dépôt visé par l&apos;accord). L&apos;APP peut ou ne pas être signataire de l&apos;accord selon la modalité d&apos;entiercement
            choisie.
            <CustomLink iconColor={"fill-white"} className="text-white" label={sharedFr.learnMore} target="_blank" path={ESCROW_AGREEMENT} />
        </>
    ),
    depositOfUpdate: "Dépôt de mise à jour",
    stepDepositRightsUpdateInfo: (holders: Deposit.Holder[]) => {
        if (holders.length === 1) {
            return (
                <p>
                    {holders[0].name} est l&apos;unique titulaire de droits sur cette filière. La modification des titulaires de droits n&apos;est pas possible
                    lors d&apos;une mise à jour. Veuillez contacter le service dépôt de l&apos;APP à <a href="mailto:app@app.asso.fr">app@app.asso.fr</a> pour
                    faire une demande d&apos;ajout ou de suppression d&apos;un ou plusieurs titulaires de droits.
                </p>
            );
        }

        return (
            <>
                <p>La titularité de droits sur cette filière est partagée entre :</p>
                <ul className="list-disc pl-6">
                    {holders.map(holder => (
                        <li key={holder.memberId}>
                            Membre APP n°{holder.longNumber} - {holder.name}
                        </li>
                    ))}
                </ul>
                <br />
                <p>
                    La modification des titulaires de droits n&apos;est pas possible lors d&apos;une mise à jour. Veuillez contacter le service dépôt de
                    l&apos;APP à <a href="mailto:app@app.asso.fr">app@app.asso.fr</a> pour faire une demande d&apos;ajout ou de suppression d&apos;un ou
                    plusieurs titulaire(s) de droits.
                </p>
            </>
        );
    },
    memberName: "Membre",
    memberNumberLabel: "N° de membre",
    subscriptionExpirationDate: "Date d'expiration de l'abonnement",
    subscriptionExpirationDateContent: (date?: string) => <>{date} (renouvelé automatiquement)</>,
    youAreConnctedTo: "Vous êtes connecté au compte de",
    accountSettings: "Paramètres du compte",
    credits: "Crédits",
    depositCreatorsAndRepresentatives: "Déposants et tiers",
    personalSettings: "Paramètres personnels",
    needHelpQuestion: "Besoin d'aide ?",
    connectedAs: "Connecté en tant que",
    memberAccount: "Compte membre",
    adminPurchaseInstructions: (firstName: string, lastName: string) => `Seul votre administrateur ${firstName} ${lastName} vous allouer de nouveaux crédits.`,
    creditValidityRules:
        "Les crédits sont valables 1 an, à compter de la date d'achat. Les crédits non utilisés ne sont pas remboursables. Ils ne sont pas non plus reportables, sauf en cas de nouvelle commande de crédit en cours de période. Dans ce cas, la date d'expiration de l'ensemble des crédits disponibles sera celle du dernier crédit acheté.",
    depositsDistributedAmongDepositStores: "Répartis entre",
    didYouKnowInitialDeposit: (
        <>
            <strong>Saviez-vous que vous pouvez désormais déposer votre code source sur l&apos;APP en effectuant un simple &quot;git push&quot; ?</strong>
            <br />
            Quelle que soit la plateforme que vous utilisez (GitHub, GitLab, Bitbucket, etc.), la protection de vos œuvres numériques s&apos;intègre facilement
            à vos flux CI/CD.
            <br />
            <a className="underline" href={CONTACT_SUPPORT} target="_blank" rel="noreferrer">
                Contactez-nous
            </a>{" "}
            pour en savoir plus.
        </>
    ),
    noCreditAvailable:
        "Votre solde de crédits est actuellement expiré ou insuffisant pour procéder à des dépôts. Nous vous invitons à acquérir au moins un crédit pour débloquer cette fonctionnalité.",
    noCreditBuyCredit: (
        <>
            Le solde de crédits est insuffisant.{" "}
            <a href={BUY_CREDITS_V1} target="_blank" className="underline" rel="noreferrer">
                Acheter des crédits.
            </a>
        </>
    ),
    whichDeposit: "Quel dépôt souhaitez-vous faire ?",
    titularityDeposit: "Titulaire de droits déposant",
    titularity: "Titulaire de droits",
    subTitleTitularity: (name: string) => (
        <p>
            Choisissez d&apos;être l&apos;unique titulaire de droits, <br /> ou sélectionnez un ou plusieurs cotitulaires <br />
            parmi ceux déjà enregistrés auprès de <strong>{name}</strong>
        </p>
    ),
    selectHolders: "Sélectionnez le ou les autres titulaires de droits",
    you: "Vous",
    holders: "Titulaires de droits",
    sharedHolder: "Titularité de droits partagée",
    addHolderV1: (
        <p>
            Partagez la titularité de droit de votre dépôt avec d&apos;autres personnes physiques ou morales.
            <br />
            <a className="underline" href={ADD_HOLDERS_V1} target="_blank" rel="noreferrer">
                Ajouter de nouveaux titulaires de droits.
            </a>
        </p>
    ),
    updateDepositStoreHowTo: (
        <>
            <p>Il s&apos;agit d&apos;un nouveau dépôt d&apos;une œuvre déjà déposée à l&apos;APP. </p> <br />
            <p>
                Faire un dépôt à titre de mise à jour vous permet de poursuivre une filière de dépôts existante et d&apos;attester des évolutions de votre
                œuvre.
            </p>{" "}
            <br />
            <p>Rappel : Un nouveau dépôt n&apos;annule pas et ne remplace pas le précédent. Nous conservons tous vos dépôts tant que vous restez membre.</p>
        </>
    ),
    searchDeposit: "Rechercher un dépôt",
    searchDepositHelper: "Nom d'un dépôt",
    upload: {
        unknownError: "Erreur inconnue",
        noInitializeUploadFunction: "Vous ne disposez pas des droits nécessaires pour téléverser des fichiers",
        initializeUploadError: (file: File) => `Erreur d'initialisation de l'envoi du fichier '${file.name}'`,
        noUploadChunkFunction: "Vous ne disposez pas des droits nécessaires pour téléverser un fichier",
        uploadChunkError: (file: File) => `Erreur lors du téléversement du fichier '${file.name}'`,
        noFinalizeUploadFunction: "Vous ne disposer pas des droits nécessaires pour finaliser le téléversement",
        finalizeUploadError: (file: File) => `Erreur lors de la validation du téléversement du fichier '${file.name}'`
    },
    unableToRequestSeal: "Impossible de demander le scellement du dépôt",
    depositStoresPlural: {
        0: "-",
        1: "1 filière",
        plural: (count: number) => `${count} filières`
    },
    allocadedCreditByYourAdmin: "Crédits alloués",
    allocatedCreditInfo: "Vous disposez de crédits alloués par votre administrateur. Il peut à tout moment vous en attribuer de nouveaux ou en en retirer.",
    consumedCredits: "Consommés",
    memberCreditBalance: "Solde de crédits du membre",
    personnalCreditBalance: "Solde de crédits personnel",
    administrateCredits: "Administrer les crédits",
    exclusiveAdministrator: "Exclusif Administrateur",
    bought: "Achetés",
    consummedByUsers: "Consommés par les utilisateurs",
    usersDefinition: "Les utilisateurs incluent à la fois les contributeurs, s'il y en a, et vous en tant qu'Administrateur.",
    checkPortfolio: "Consulter le portefeuille",
    adminHasNotAllocatedCredit: "Votre administrateur ne vous a pas encore alloué de crédit",
    uploadFilesInProgress: "Téléversement.",
    uploadCancelInProgress: "Annulation du téléversement en cours.",
    sealRequestInProgress: "Téléversement terminé. Scellement en préparation.",
    uploadSizeMessage: (totalSize: number, uploadedSize: number, isDone: boolean) => {
        const convert = bytesConverter("fr", { ...fr.file, decimal: 1 });
        if (isDone) {
            return (
                <div className="flex items-center">
                    <TickIcon className="h-3 w-3 mr-1 fill-success" />
                    Fichiers reçus | {convert(uploadedSize)} sur {convert(totalSize)}
                </div>
            );
        }

        return `En cours | ${convert(uploadedSize)} sur ${convert(totalSize)}`;
    },
    pleaseWait: "Veuillez patienter",
    findOrAddHolder: "Recherchez et/ou ajoutez un autre titulaire de droits",
    noResult: "Aucun résultat",
    noHolder: "Aucun titulaire de droits selectioné",
    otherHolders: "Autres titulaires de droits selectionés",
    pack: {
        free: {
            price: "Gratuit",
            title: "Aucun",
            description: ["Le ou les titulaires de droits sont les seuls habilités à demander une copie des éléments déposés."]
        },
        small: {
            price: "900 HT /an",
            title: "Entiercement bipartite",
            subtitle: "clause d'accès",
            description: ["Le ou les titulaires de droits sont les seuls habilités à demander une copie des éléments déposés."]
        },
        mid: {
            price: "",
            title: "Clause d'accès",
            subtitle: "clause d'accès",
            description: ["Le ou les titulaires de droits sont les seuls habilités à demander une copie des éléments déposés."]
        },
        high: {
            price: "1200 HT /an",
            title: "Entiercement tripartite",
            description: [
                "Le dépôt fera l'objet d'un contrat d'entiercement dont l'APP est signataire",
                "Un modèle de contrat d'entiercement vous sera adressé par notre service juridique dès validation de votre dépôt."
            ]
        }
    },
    meanStep: {
        option1: {
            title: "Dépôt numérique",
            helperText: "Jusqu'à 10Go",
            description: [
                "Votre création numérique sera transférée à l'APP de façon dématérialisée via un tunnel chiffré.",
                "Une fois reçue, l'APP enregistre, chiffre et archive votre création de façon électronique.",
                "Le certificat de dépôt est ensuite disponible sur votre compte APP."
            ]
        },
        option2: {
            title: "Dépôt physique",
            helperText: "Aucune limite de poids",
            description: [
                "Vous pouvez aussi transmettre à l'APP votre création numérique en 2 exemplaires sur supports physiques.",
                "L'APP met les 2 exemplaires reçus sous scellé et les conserve en France.",
                "Le certificat de dépôt est ensuite disponible sur votre compte APP."
            ]
        }
    },
    depositTypeStep: {
        standard: {
            title: "1 crédit",
            subtitle: "Standard (1 crédit)",
            description: ["A remplir", "A remplir"]
        },
        verified: {
            title: "- crédits",
            subtitle: "Vérifié",
            description: ["A remplir", "A remplir"]
        },
        controlled: {
            title: "- crédits",
            subtitle: "Contrôlé",
            description: ["A remplir", "A remplir"]
        },
        helperText: (solde: number) => (
            <>
                Pour effectuer un dépôt, vous devez disposer d&apos;un solde suffisant de crédits disponibles.
                <br />
                <strong>Solde de crédits disponibles : {solde}</strong>
            </>
        ),
        buyCredits: "Acheter des crédits supplémentaires"
    },
    goBackAndCancel: "Revenir en arrière et annuler",
    yourProduct: "Informations de votre œuvre numérique",
    depositMode: "Mode de dépot",
    infoNoContractType: "Aucun entiercement : Le ou les titulaires de droits sont les seuls habilités à demander une copie des éléments déposés.",
    titularityTooltip: (name: string) => (
        <>
            <p>
                Si vous choisissez &quot;Unique&quot;, {name} apparaitra comme le seul titulaire de droits du dépôt sur le certificat de dépôt. <br />
                Dans quel cas choisir cette option ? Par exemple, {name} est le créateur unique de l&apos;œuvre déposée.
            </p>
            <br />
            <p>
                Si vous choisissez &quot;Partagée&quot;, {name} apparaitra au côté d&apos;autres titulaires de droits sur le certificat de dépôt. <br /> Dans
                quel cas choisir cette option ? Par exemple, le dépôt d&apos;une œuvre collective ou de collaboration.
            </p>
        </>
    ),
    transmissionTooltip: (
        <>
            <p>
                Dépôt Numérique : Votre création numérique sera transférée à l&apos;APP de façon dématérialisée via un tunnel chiffré. Une fois reçue,
                l&apos;APP enregistre, chiffre et archive votre création de façon électronique. Le certificat de dépôt est ensuite disponible sur votre compte
                APP.
            </p>
            <br />
            <p>
                Dépôt Physique : Vous pouvez aussi transmettre à l&apos;APP votre création numérique en 2 exemplaires sur supports physiques. L&apos;APP met les
                2 exemplaires reçus sous scellé et les conserve en France. Le certificat de dépôt est ensuite disponible sur votre compte APP.
            </p>
        </>
    ),
    subscriptionTypeTooltip: "En cours de réalisation",
    projectName: "InterDeposit",
    spaceName: "Espace membre",
    joint: "Conjointe",
    self: "Individuelle",
    validateAndGoToWallet: "Valider et aller au portefeuille",
    responsabilityToolTip: (memberName: string) => (
        <p>
            Parce que vous venez de réaliser un dépôt initial, vous pouvez maintenant choisir de partager la responsabilité des mises à jour du dépôt à un ou
            plusieurs contributeurs déjà connus de votre compte membre APP. Cette action reste disponible à tout moment depuis la filière du dépôt par
            l&apos;administrateur de {memberName}.
        </p>
    ),
    contributorTooltip: "Les personnes affichées auront le droit de mettre à jour la filière de ce dépôt initial.",
    lastName: "Nom",
    firstName: "Prénom",
    email: "E-mail",
    professionalEmail: "Un email professionnel",
    phoneNumber: "Numéro de téléphone",
    phoneNumberHelper: "Un numéro de téléphone professionnel",
    job: "Fonction",
    socialName: "Dénomination sociale",
    socialNameHelper: "Le nom du membre APP",
    legalStatus: "Statut juridique",
    immatriculation: "Numéro d'immatriculation",
    immatriculationHelper: "Par exemple : Siren en France",
    address1: "Adresse 1",
    address2: "Adresse 2",
    postalCode: "Code postal",
    city: "Ville",
    state: "Etat / Providence",
    countryName: "Pays",
    personnalInfoAndPassword: "Informations personnelles et mot de passe",
    yourPersonnalInfo: "Vos informations personnelles",
    yourInformationsKnownByAPP: "Les informations vous concernant, connues uniquement par l'APP",
    identity: "Identité",
    professionalContact: "Coordonnées professionnelles",
    professionnalContactInfo:
        "Vos coordonnées professionnelles sont utilisées par l'APP exclusivement dans le cadre de l'administration de votre portefeuille ou pour le suivi des dossiers en cours. Elles ne sont en aucun cas utilisées à d'autres fins.",

    professionnalAddress: "Adresse professionnelle",
    professionnalAddressInfo:
        "Votre adresse professionnelle a la possibilité d'être distincte de celle enregistrée par l'administrateur pour l'adhésion de votre compte à l'APP.",
    save: "Enregistrer",
    yourPassword: "Votre mot de passe",
    yourPasswordInfo:
        "Choisissez avec soin un mot de passe robuste pour renforcer la sécurité de votre compte. Assurez-vous de le mémoriser soigneusement, car aucune autre personne que vous ne devrait en avoir connaissance. Par mesure de sécurité évidente, évitez absolument de partager votre mot de passe.",

    currentPassword: "Mot de passe actuel",
    newPassword: "Nouveau mot de passe",
    confirmNewPassword: "Confirmez le nouveau mot de passe",
    invalidEmail: "L'email n'est pas valide",
    changePassword: "Changer le mot de passe",
    manageCredit: "Administration des crédits",
    paymentAndInvoices: "Paiements et facturation",
    paymentAndResumeInvoices: "Paiements et historique de facturation",
    download: "Télécharger",
    payments: "Paiements",
    invoices: "Factures",
    transmissionSucessDescription: (name: string) => (
        <>
            <p>
                L&apos;enregistrement numérique de <strong>{name}</strong> a été effectué.
                <br /> Cet enregistrement est maintenant disponible dans votre portefeuille.
            </p>
            <br />
            <p>Le processus de scellement numérique a déjà commencé.</p>
            <br />
            <p>Une fois le scellement terminé, le certificat de votre dépôt sera accessible depuis votre portefeuille.</p>
        </>
    ),
    depositRepresentativeTitle: "Mandant(s) titulaire(s) de droits sélectionnés",
    depositRepresentativeInfo: (
        <>
            <p>Déposé au nom d&apos;autres mandants personnes physiques ou morales en tant que mandataires</p>
            <a href="#" className="underline">
                Ajouter de nouveaux mandants
            </a>
        </>
    ),
    depositRpresentativePlaceholder: "Recherchez et/ou ajoutez un autre mandant titulaire de droits",
    noDepositRepresentativeSelected: "Aucun mandant titulaire de droits sélectionné",
    cgu: "CGU",
    versionNumber: "Numéro de version",
    invitationBodyText: (request: Request) => (
        <>
            <p>
                <strong>{request.requester.user.firstName + request.requester.user.lastName} </strong>vous a invité à devenir{" "}
                {INVITATION_TYPE[request.type].toLocaleLowerCase()} de
                <strong> {request.requester.member.name}</strong> pour déposer en son nom.
            </p>
            <p>
                En tant que {INVITATION_TYPE[request.type].toLocaleLowerCase()}, vous ne sera pas directement rattaché au compte de
                <strong> {request.requester.member.name}</strong> qui restera titulaire unique de l&apos;œuvre que vous déposerez.
            </p>
        </>
    ),
    pendingInvitation: "Invitation en attente",
    acceptRequestInvitation: "Vous êtes sur le point d'accepter l'invitation.",
    rejectRequestInvitation: "Vous êtes sur le point de refuser l'invitation.",
    alertInvitationAccepted: "L'invitation a été acceptée.",
    alertInvitationRejected: "L'invitation a été refusée.",
    articlesSelection: "Sélection d'articles",
    paymentMethod: "Mode de paiement",
    goToPaymentPage: "Passer au paiement",
    chooseCreditsAndValidate: 'Choisissez les crédits qu\'il vous faut, puis cliquez sur "Passer au paiement"',
    choosePaymentMethodAndValidate: 'Veuillez choisir un mode de paiement, puis cliquez sur "Valider"',
    checkOrderAndValidate: 'Vérifiez la commande, puis cliquez sur "Passer la commande et payer" pour valider',
    validateOrderAndPay: "Passer la commande et payer",
    creditCard: "Carte de débit ou de crédit",
    bankTransfer: "Virement bancaire",
    order: "Commande",
    thanksForYourOrder: "Merci pour votre commande",
    yourCreditsWillBeAdded: "Vos crédits seront ajoutés automatiquement.",
    backToCreditsManagement: "Retour à la gestion des crédits",
    formatPercent: (price: number) => `${formatNumber(price)}%`,
    formatPrice: (price: number) => `${formatNumber(price)} €`,
    globalRebatePercent: (percent: number) => `Remise (${formatNumber(percent)}%)`,
    vatPercents: (percents: number[]) =>
        `TVA (${percents
            .map(formatNumber)
            .map(percent => `${percent}%`)
            .join(" / ")})`,
    paymentError: "Erreur de paiement",
    unitPriceVatExcluded: "Prix unitaire HT",
    emptyShoppingCart: "Votre panier est vide",
    totalPriceVatExcluded: "Sous-total HT",
    totalPriceVatIncluded: "Montant total TTC",
    vatExcludedSymbol: "HT",
    externalReference: "Référence bon de commande",
    paymentMethods: {
        CREDIT_CARD: { label: "Carte de débit ou de crédit" },
        BANK_TRANSFER: {
            label: "Virement bancaire",
            description: (
                <ul>
                    <li>Titulaire : AGENCE POUR LA PROTECTION DES PROGRAMMES - APP</li>
                    <li>Domiciliation : NANTES ENTREPRISES (04348)</li>
                    <li>RIB : 30003 04348 00050038326 01</li>
                    <li>IBAN : FR7630003043480005003832601</li>
                    <li>BIC : SOGEFRPP</li>
                </ul>
            )
        }
    },
    orderAccepted: "Votre commande a été acceptée",
    depositMembersCount: (number: number, type: DepositMember.Type) => {
        const maxRender = () => Math.min(2, Math.max(0, number));

        return `${INVITATION_TYPE[type]}s (${maxRender()} sur ${number} au total)`;
    },
    noExternalDepositMembers: "Vous n'avez aucun collaborateur tier pour le moment",
    ownerDeposit: memberInfos => `Les dépôts réalisés par ${memberInfos.name}`,
    previewLastDepositStores: "Aperçu de vos dernières filières",
    depositStoreCount: {
        0: "Aucune filière",
        1: "1 filière",
        plural: (count: number) => `${count} filières au total`
    },
    seeMore: "Voir plus",
    createYourFirstDeposit: "Réalisez votre premier dépôt pour créer une nouvelle filière",
    yourExternalContributors: "Vos collaborateurs tiers",
    addContributor: "Ajouter un collaborateur",
    tooltipCollaborator: `Vos collaborateurs tiers sont les mandataires et mandants liés à votre espace membre. Cliquez sur "voir plus" pour plus de détails.`,
    manage: "Administrer"
};
