import { Link } from "./Hateoas";

export interface DepositMember {
    id: string;
    fullNumber: string;
    siren?: string;
    owner: {
        firstName: string;
        lastName: string;
        email: string;
    };
    request: {
        status: DepositMember.Status;
        requestedAt: string;
        type: DepositMember.Type;
        repliedAt?: string;
    };
    name: string;
    _links: {
        removeMemberManager: Link;
    };
}
export namespace DepositMember {
    export enum Status {
        ACCEPTED = "ACCEPTED",
        REFUSED = "REFUSED",
        SENT = "SENT",
        INVALID = "INVALID"
    }
    export enum Type {
        MANDATE = "MANDATE",
        REPRESENTATIVE = "REPRESENTATIVE",
        COTITULARITY = "COTITULARITY",
        CONTRIBUTOR = "CONTRIBUTOR",
        HOLDER = "HOLDER"
    }
}
