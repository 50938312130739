export default function TransferIcon({ className }: { className?: string }) {
    return (
        <svg fill="currentColor" viewBox="0 0 16 16" height="100%" width="100%" className={className}>
            <path
                fillRule="evenodd"
                d="M1 11.5a.5.5 0 00.5.5h11.793l-3.147 3.146a.5.5 0 00.708.708l4-4a.5.5 0 000-.708l-4-4a.5.5 0 00-.708.708L13.293 11H1.5a.5.5 0 00-.5.5zm14-7a.5.5 0 01-.5.5H2.707l3.147 3.146a.5.5 0 11-.708.708l-4-4a.5.5 0 010-.708l4-4a.5.5 0 11.708.708L2.707 4H14.5a.5.5 0 01.5.5z"
            />
        </svg>
    );
}
