import { yupResolver } from "@hookform/resolvers/yup";
import {
    addHolder,
    addHolders,
    Button,
    Deposit,
    FinalizeUploadResponse,
    getMemberApi,
    getMemberDetails,
    InterdepositContext,
    Member,
    Popup,
    ProgressBar,
    saveDepositMetadata,
    sealDeposit,
    setRepresentative,
    setSharedHolders,
    setSingleHolder,
    SimpleListOption,
    updateDeposit,
    UploadFileResponse,
    useApi,
    useI18n,
    UserContext,
    useToast
} from "@vaultinum/app-sdk";
import { isUndefined, negate } from "lodash";
import { useContext, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DepositStepLayout } from ".";
import { dataForm } from "../../helpers";
import { useStepController, useUpload } from "../../hooks";
import { Translation } from "../../i18n";
import { DepositStep } from "../../model";
import { DepositFormNumericSchema } from "../../schemas";
import { DepositContributors, DepositRights } from "../../schemas/DepositFormNumericSchema";
import { toDepositStorePageUpdate, WALLET_PAGE } from "../../services";
import { INPUT_FILES_NAME } from "../DropZone/DropZone";

export default function CreateDeposit({ step = DepositStep.INFORMATION, prefillDeposit }: { step?: DepositStep; prefillDeposit?: Deposit }): JSX.Element {
    const [activeStep, setActiveStep] = useState<DepositStep>(step);
    const [memberInfos, setMemberInfos] = useState<Member>();
    const [popUpLoading, setPopUpLoading] = useState(false);
    const [cancelingUpload, setCancelingUpload] = useState(false);
    const [canCancelUpload, setCanCancelUpload] = useState(true);

    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const { fetchApi, isLoading } = useApi();
    const toast = useToast();
    const { translation } = useI18n<Translation>();
    const { uploadFiles, uploadedSizeInBytes, totalUploadSizeInBytes, uploadPercent, isUploadDone, cancelUpload } = useUpload();
    const { depositStore, deposit } = useContext(InterdepositContext);

    const methods = useForm<FieldValues>({
        mode: "onChange",
        resolver: yupResolver(DepositFormNumericSchema),
        defaultValues: {
            type: Deposit.Type.DIGITAL,
            contractType: "standard",
            subscriptionTypeCode: "S",
            countryCode: "FR",
            agreements: false,
            listOfFiles: [],
            holders: [],
            representatives: [],
            contributors: DepositContributors.SELF,
            depositRights: DepositRights.UNKNOWN
        }
    });

    const { trigger, getValues, reset, watch, setValue, handleSubmit } = methods;
    const StepController = useStepController(methods, activeStep, memberInfos);

    useEffect(() => {
        reset(formValues => ({
            ...formValues,
            name: (deposit ?? prefillDeposit)?.name,
            subtitle: (deposit ?? prefillDeposit)?.subtitle,
            countryCode: (deposit ?? prefillDeposit)?.countryCode,
            workType: (deposit ?? prefillDeposit)?.workType,
            workNature: (deposit ?? prefillDeposit)?.workNature,
            productClass: (deposit ?? prefillDeposit)?.productClass,
            workVersion: (deposit ?? prefillDeposit)?.workVersion ?? "",
            workVersionDate: (deposit ?? prefillDeposit)?.workVersionDate
        }));
    }, [deposit, prefillDeposit]);

    const getHolders = (): SimpleListOption[] => {
        return getValues("holders");
    };

    const getHoldersIds = (): string[] => {
        return getHolders().map(holder => holder.id);
    };

    const triggerSealDeposit = async (depositResponse: UploadFileResponse | FinalizeUploadResponse) => {
        const sealedFn = sealDeposit(depositResponse);

        if (!sealedFn) {
            toast.error(translation.unableToRequestSeal);

            return false;
        }

        const sealResponse = await fetchApi(sealedFn);

        if (!sealResponse) {
            return false;
        }

        return true;
    };

    const loadMembersInfos = async () => {
        const memberApi = await getMemberApi();
        const memberFn = getMemberDetails(memberApi);

        if (memberFn) {
            const infos = await fetchApi(memberFn);
            setMemberInfos(infos);
        }
    };

    useEffect(() => {
        void loadMembersInfos();
    }, []);

    const handleStep = (value: number) => {
        setActiveStep(prevstate => prevstate + value);
    };

    const saveDeposit = async (data: FieldValues, saveProgress?: boolean) => {
        const userId = user?.sub;
        const memberId = user?.memberId;

        if (deposit) {
            const { type, ...filterData } = dataForm(data);
            await fetchApi(
                () => updateDeposit(deposit, { id: deposit.id, ...filterData }),
                depositResponse => {
                    if (saveProgress) {
                        toast.success(translation.depositSavingAlert(depositResponse.name));
                        navigate(WALLET_PAGE);
                    } else {
                        toast.success(translation.depositSavingAlert(depositResponse.name));
                        handleStep(+1);
                    }
                }
            );
        }

        if (!deposit && depositStore) {
            const createDepositFn = saveDepositMetadata(depositStore);
            if (createDepositFn && userId && memberId) {
                const createDepositResponse = await fetchApi(() => createDepositFn(userId, memberId, dataForm(data)));
                if (createDepositResponse) {
                    toast.success(translation.depositCreateAlert(createDepositResponse.name));
                    navigate(toDepositStorePageUpdate(depositStore, createDepositResponse));
                }
            }
        }
    };

    const uploadFilesAndRequestSeal = async () => {
        if (deposit) {
            setPopUpLoading(true);
            setCanCancelUpload(true);

            const uploadFileResponses = await uploadFiles(deposit, getValues(INPUT_FILES_NAME));

            if (uploadFileResponses && uploadFileResponses.every(negate(isUndefined)) && uploadFileResponses[0]) {
                setCanCancelUpload(false);

                const sealResponse = await triggerSealDeposit(uploadFileResponses[0]);

                if (sealResponse) {
                    handleStep(+1);
                }
            }

            setPopUpLoading(false);
            setCancelingUpload(false);
        }
    };

    const submitRepresentative = async () => {
        if (deposit) {
            const postRepresentativesFn = setRepresentative(deposit);

            if (postRepresentativesFn) {
                await fetchApi(
                    () => postRepresentativesFn(),
                    response => {
                        if (response) {
                            const addHoldersFn = addHolders(response);
                            if (addHoldersFn) {
                                void fetchApi(
                                    () => addHoldersFn(getHoldersIds()),
                                    () => handleStep(+1)
                                );
                            }
                        }
                    }
                );
            }
        }
    };

    const submitSharedTitularity = async () => {
        if (deposit) {
            const postShraedTitularityFn = setSharedHolders(deposit);

            if (postShraedTitularityFn) {
                await fetchApi(
                    () => postShraedTitularityFn(),
                    response => {
                        if (response) {
                            const addHoldersFn = addHolders(response);
                            if (addHoldersFn) {
                                void fetchApi(
                                    () => addHoldersFn(getHoldersIds()),
                                    () => handleStep(+1)
                                );
                            }
                        }
                    }
                );
            }
        }
    };

    const submitOneTitularity = async () => {
        if (deposit) {
            const postOneTitularityFn = setSingleHolder(deposit);

            if (postOneTitularityFn) {
                await fetchApi(
                    () => postOneTitularityFn(),
                    () => handleStep(+1)
                );
            }
        }
    };

    const submitStepRights = () => {
        switch (getValues("depositRights")) {
            case DepositRights.REPRESENTATIVE:
                void submitRepresentative();
                break;
            case DepositRights.SHARED_TITULARITY:
                void submitSharedTitularity();
                break;
            default:
                void submitOneTitularity();
                break;
        }
    };

    const submit = async () => {
        await handleSubmit(data => saveDeposit(data, true))();
    };

    const handleForm = async (value: number) => {
        if (StepController.validateFields) {
            if (await trigger(StepController.validateFields)) {
                if (activeStep === DepositStep.INFORMATION) {
                    await handleSubmit(data => saveDeposit(data))();
                } else if (activeStep === DepositStep.RIGHTS && deposit && addHolder(deposit)) {
                    submitStepRights();
                } else if (activeStep === DepositStep.INFORMATION_RECAP) {
                    await uploadFilesAndRequestSeal();
                } else {
                    handleStep(value);
                }
            }
        } else if (activeStep === DepositStep.TRANSMISSION && getValues(INPUT_FILES_NAME).length > 0) {
            handleStep(value);
        } else {
            handleStep(value);
        }
    };

    const uploadPopupMessage = () => {
        if (cancelingUpload) {
            return translation.uploadCancelInProgress;
        }

        if (isUploadDone) {
            return translation.sealRequestInProgress;
        }

        return translation.uploadFilesInProgress;
    };

    const startCancelUpload = () => {
        if (!cancelUpload) {
            return;
        }

        setCancelingUpload(true);
        cancelUpload();
    };

    useEffect(() => {
        if (!depositStore) {
            setValue("holders", []);
        }
    }, [watch("depositRights")]);

    const previousStep = () => {
        handleStep(-1);
    };

    const nextStep = () => {
        void handleForm(+1);
    };

    return (
        <>
            <FormProvider {...methods}>
                <DepositStepLayout
                    depositStep={activeStep}
                    forwardButtonAction={nextStep}
                    backButtonAction={previousStep}
                    submit={submit}
                    stepController={StepController}
                    isLoading={isLoading}
                />
            </FormProvider>

            <Popup title={translation.pleaseWait} isOpen={popUpLoading}>
                <ProgressBar label={uploadPopupMessage()} indicator={`${uploadPercent}%`} progress={String(uploadPercent)} />
                <div className="text-color-disabled text-xs mt-1">
                    {translation.uploadSizeMessage(totalUploadSizeInBytes, uploadedSizeInBytes, isUploadDone)}
                </div>
                <div className="flex justify-center mt-2.5">
                    <Button
                        label={translation.cancel}
                        onClick={startCancelUpload}
                        disabled={!canCancelUpload || !cancelUpload}
                        variant="link"
                        size="sm"
                        underline
                    />
                </div>
            </Popup>
        </>
    );
}
